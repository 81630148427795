/**
 * @author Piotrek Polus / SoInteractive <ppolus@sointeractive.pl>
 */

var okonferencji=$('#about');
var dlaczego=$('#why');
var agenda=$('#agenda');
var prelegent=$('#prelegent');
var miejsce=$('#place');
var kontakt=$('#contact');

function checkSub(){
    if(okonferencji.isOnScreen(0.5, 0.5)){
        $('nav ul li a').removeClass('active');
        $('nav ul li a[data-target="about"]').addClass('active');
        menuActivity();
        return 0;
    }
    if(dlaczego.isOnScreen(0.5, 0.5)){
        $('nav ul li a').removeClass('active');
        $('nav ul li a[data-target="why"]').addClass('active');
        menuActivity();
        return 0;
    }
    if(agenda.isOnScreen(0.5, 0.5)){
        $('nav ul li a').removeClass('active');
        $('nav ul li a[data-target="agenda"]').addClass('active');
        menuActivity();
        return 0;
    }
    if(prelegent.isOnScreen(0.5, 0.5)){
        $('nav ul li a').removeClass('active');
        $('nav ul li a[data-target="prelegent"]').addClass('active');
        menuActivity();
        return 0;
    }
    if(miejsce.isOnScreen(0.5, 0.5)){
        $('nav ul li a').removeClass('active');
        $('nav ul li a[data-target="place"]').addClass('active');
        menuActivity();
        return 0;
    }
    if(kontakt.isOnScreen(0.5, 0.5)){
        $('nav ul li a').removeClass('active');
        $('nav ul li a[data-target="contact"]').addClass('active');
        menuActivity();
        return 0;
    }
}

$(window).scroll(function(){
    checkSub();
});

