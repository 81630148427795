/**
 * @author Piotrek Polus / SoInteractive <ppolus@sointeractive.pl>
 */

function mapInit() {
    var mapCanvas = document.getElementById('mapa');

    var cordX = mapCanvas.getAttribute("data-cordX");
    var cordY = mapCanvas.getAttribute("data-cordY");

    var mapOptions = {
        center: new google.maps.LatLng(cordX, cordY),
        zoom: 14,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoomControlOptions: {
            style: google.maps.ZoomControlStyle.LARGE,
            position: google.maps.ControlPosition.TOP_RIGHT
        }
    };
    var map = new google.maps.Map(mapCanvas,mapOptions);
    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(cordX, cordY),
        map: map
    });
}
mapInit();