$('#registerButton').on('click',function(){
    $.ajax({
        type : 'POST',
        url : '/php/doRegistration.php',
        dataType : 'json',
        data: $('#registerModal form').serializeArray(),

        success : function(data){
            if (data.success==1){
                notice('Dokonano rejestracji. Dziękujemy','ok');
                closeModal();
            } else {
                if(data.msg){
                    notice(data.msg,'error');
                }

                if(data.field){
                    $("#registerModal form input[name="+data.field+"]").addClass('error');
                    setTimeout(function(){
                        $("#registerModal form input[name="+data.field+"]").removeClass('error');
                    },1000)
                }

            }
        },
        error : function() {
            notice('Wystąpił nieoczekiwany problem','error');
        }
    });
});

if(window.location.hash=='#rejestracja'){
    openModal('registerModal');
}