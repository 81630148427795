var nav = $('nav');

function menuActivity(){
    var active = $('nav a.active');
    var marker = $('nav .label .marker');

    if(marker.offset().left == active.offset().left){

    } else if(marker.offset().left < active.offset().left){
        marker.removeClass('left');
        marker.addClass('right');
    } else {
        marker.removeClass('right');
        marker.addClass('left');
    }
    marker.css('left',active.offset().left+20);
    marker.css('right',$(document).width() - active.offset().left - 20 - active.width());
}
$('a[data-target]').each(function(){
    $(this).on('click',function(){
        var target = $(this).attr('data-target');

        if (target) {

            $('html, body').animate({
                scrollTop: $('#'+target).offset().top - nav.height()
            }, 500);
        }
    })
});

$('#mobilemenuSwitch').on('click',function(){
    $('body').toggleClass('openMenu');
});

//$(window).scroll(function(){
//    menuActivity();
//});

$(window).resize(function(){
    menuActivity();
});

$(window).load(function(){
    menuActivity();
});