// Setter cookiesów

function setCookie(name, value, days){
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        expires = "; expires="+date.toGMTString();
    }
    else {
        expires = "";
    }
    document.cookie = name+"="+value+expires+"; path=/";
}

// Getter cookiesów

function getCookie(name){
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1,c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length,c.length);
        }
    }
    return null;
}

// Funkcja do obslugi cookie alertu
// Przy wywolaniu bez argumentu sprawdza zawartosc cookiesa i ewentualnie wyswietla komunikat, przy wywolaniu z argumentem ustawia cookie i chowa alert

function cookieAlert(action){

    action = typeof action !== 'undefined' ? action : 0;

    if(action){
        setCookie('cookieAcceptance', 1, 365);
    }

    if(getCookie('cookieAcceptance')==1){
        $('body').removeClass('showcookieAlert');
    } else {
        if(!($('#cookieAlert').length)){
            $('<div id="cookieAlert">Ta strona korzysta z plików cookie ("ciasteczka"). Pozostając na niej, wyrażasz zgodę na korzystanie z cookies. <a onclick="cookieAlert(1)">Zamknij</a></div>').appendTo($('body'));
        }
        $('body').addClass('showcookieAlert');
    }
}

// noticeCount = ilosc aktywnych powiadomien
var noticeCount=0;

// noticeCount = obiekt aktywnego powiadomienia
var noticeActive= null;

// Funkcja do wysietlania powiadomien
//      text - tresc komunikatu
//      type - {info,ok,warning,error} - rodzaj powiadomienia

function notice(text,type){
    type = typeof type !== 'undefined' ? type : '';

    if(noticeActive==null){
        noticeActive=$('<div class="notice" data-type="'+type+'">'+text+'</div>').appendTo($('body'));

    } else {
        noticeActive.html(text).attr('data-type',type);
    }

    setTimeout(function(){
        $('body').addClass('showInfo');
        noticeCount++;
    },100);

    setTimeout(function(){
        noticeCount--;
        if(noticeCount<1){
            $('body').removeClass('showInfo');
            setTimeout(function(){
                noticeActive.remove();
                noticeActive= null;
            },400);
        }

    },5000)
}

// klasa na body zalezna od rodzaju ekranu

function detectScreen(){
    var width=0;
    if (document.body && document.body.offsetWidth) {
        width = document.body.offsetWidth;
    }
    if (document.compatMode=='CSS1Compat' &&
        document.documentElement &&
        document.documentElement.offsetWidth ) {
        width = document.documentElement.offsetWidth;
    }
    if (window.innerWidth && window.innerHeight) {
        width = window.innerWidth;
    }

    var type='';

    if(Modernizr.touch){
        if(width<=680){
            type='phone'
        } else {
            type='tablet'
        }
    } else {
        if(width<=1366){
            type='notebook'
        } else {
            type='desktop'
        }
    }

    $('html').addClass(type);

}

// okna modalne

var activeModal = null;
var overflow ;

function openModal(idmodal){
    closeModal();
    $('body').addClass('openModal');
    if($('#'+idmodal)){
        activeModal = $('#'+idmodal).css('top',$(window).scrollTop()).removeClass('close').addClass('open');
    }
    if(!overflow){
        overflow = $('<div class="overflow"></div> ').appendTo($('body'));
    }
    overflow.one('click', function()
    {
        closeModal();
    });

}

function closeModal(){
    $('body').removeClass('openModal');
    $('.modal.open').removeClass('open').addClass('close').css('display','block');;
    setTimeout(function(){
        $('.modal.close').css('display','none');
    }, 300);
}

function bindModals(){
    $('.openModal').each(function(){
        $(this).on('click',function(e){
            e.stopPropagation();
            openModal($(this).data('modal'));
        })
    });
}

$(document).ready(function(){
    cookieAlert();
    detectScreen();
    bindModals();
});
